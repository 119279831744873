import {StaticImage} from "gatsby-plugin-image";
import * as React from "react";
import ContentBlock from "../Layout/ContentBlock";
import * as logosStyles from "./logos.module.css";

const Logos = () => {
    const rightContent = (
        <div className={logosStyles.logos}>
            <img src="/svg/logos.svg" alt="" />
            <div className={logosStyles.logos__gradientContainer}>
                <StaticImage
                    src="../../assets/images/about/logos/blur.png"
                    alt=""
                    placeholder="none"
                    loading="eager"
                    layout="fixed"
                />
            </div>
        </div>
    );
    return (
        <ContentBlock
            alignLeft
            title="Как мы создали продукт"
            desc="В 2013 году мы запустили сервис ITSM 365 для того, чтобы дать возможность малому и среднему бизнесу вывести свой сервис на новый качественный уровень. Для этого мы использовали лучшую платформу на российском рынке ITSM – Naumen SMP, а также опыт Naumen в сфере автоматизации ИТ-подразделений. Выстроив уникальную бизнес модель и максимально снизив издержки, мы заслужили доверие клиентов по всей стране и выросли из небольшого подразделения компании в самостоятельный и окупаемый бизнес, сервисами которого пользуются сотни компаний и десятки тысяч их клиентов."
            rightContent={rightContent}
        />
    );
};

export default Logos;
