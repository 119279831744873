import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import Section from "./Section";
import SectionTitle from "./Section/Title";
import SectionDescription from "./Section/Description";
import * as contentBlockStyles from "./content-block.module.css";

const cx = classNames.bind(contentBlockStyles);

const ContentBlock = ({id, title, desc, rightContent, leftContent, reversed, className, alignTop, alignLeft}) => {
    return (
        <Section id={id}>
            <div
                className={cx(
                    {
                        contentBlock: true,
                        contentBlock_reversed: reversed,
                        contentBlock_alignTop: alignTop,
                    },
                    className,
                )}
            >
                <div
                    className={cx({
                        contentBlock__desc: true,
                        contentBlock__desc_left: alignLeft,
                    })}
                >
                    <SectionTitle>{title}</SectionTitle> {desc && <SectionDescription>{desc}</SectionDescription>}{" "}
                    {leftContent && <div className={contentBlockStyles.contentBlock__leftContent}>{leftContent}</div>}
                </div>
                {rightContent && <div>{rightContent}</div>}
            </div>
        </Section>
    );
};

ContentBlock.defaultProps = {
    id: "",
    className: "",
    desc: "",
    leftContent: null,
    rightContent: null,
    reversed: false,
    alignTop: false,
    alignLeft: false,
};

ContentBlock.propTypes = {
    title: PropTypes.node.isRequired,
    desc: PropTypes.node,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    reversed: PropTypes.bool,
    className: PropTypes.string,
    alignTop: PropTypes.bool,
    alignLeft: PropTypes.bool,
    id: PropTypes.string,
};

export default ContentBlock;
