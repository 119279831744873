import {graphql} from "gatsby";
import * as React from "react";
import NaumenLogo from "../assets/svg/naumen-logo.svg";
import Layout from "../components/Layout/layout";
import Container from "../components/Layout/Container";
import Section from "../components/Layout/Section";
import SectionTitle from "../components/Layout/Section/Title";
import SectionDescription from "../components/Layout/Section/Description";
import Seo from "../components/SEO/SEO";
import AboutHero from "../components/About/Hero";
import Slider from "../components/Common/Slider";
import SliderPagination from "../components/Common/Slider/Pagination";
import Logos from "../components/About/Logos";
import SliderNavigation from "../components/Common/Slider/Navigation";
import FreeDemoForm from "../components/Form/FreeDemo";
import Media from "../components/Common/Media";
import Button from "../components/UI/Button";
import Link from "../components/UI/Link";
import * as aboutStyles from "./about.module.css";

const ContactPage = ({data: {aboutPage, articles}}) => {
    const authorsWithArticlesIDs = articles.edges.map(({node}) => node.authors.map(({id}) => id)).flat();
    const {hero, seo, team} = aboutPage;

    return (
        <Layout>
            <Seo seo={seo} />
            <AboutHero title={hero.title} buttonText={hero.buttonText} media={hero.media} variant={hero.variant} />
            <Section
                title="Почему выбирают ITSM 365?"
                desc="Наша команда — вендор и интегратор в одном лице, мы умеем слушать и быстро адаптироваться под требования бизнеса наших клиентов. Мы следим за тенденциями
в предметной области и прорабатываем новые инструменты, которые позволят повысить качество и эффективность бизнеса наших клиентов (как следствие и нашего), делимся своим опытом и помогаем выстраивать надежные процессы."
            />
            <Section>
                <Slider
                    title="Наша команда"
                    alignLeft
                    navigation={<SliderNavigation />}
                    pagination={<SliderPagination />}
                    sliderClassName={aboutStyles.about__slider}
                    containerClassName={aboutStyles.about__sliderContainer}
                >
                    {team
                        .sort(
                            ({sortOrder: leftSortOrder}, {sortOrder: rightSortOrder}) => rightSortOrder - leftSortOrder,
                        )
                        .map(({id, name, position, avatar}) => {
                            return (
                                <div key={id}>
                                    {avatar && (
                                        <Media
                                            media={{youtubeVideoURL: "", content: avatar}}
                                            className={aboutStyles.about__authorImage}
                                        />
                                    )}
                                    <h3 className={aboutStyles.about__authorName}>{name}</h3>
                                    <p className={aboutStyles.about__authorPosition}>{position}</p>
                                    {authorsWithArticlesIDs.includes(id) && (
                                        <Link to={`/blog?author=${id}`}>Посмотреть статьи</Link>
                                    )}
                                </div>
                            );
                        })}
                </Slider>
            </Section>
            <Logos />
            <Section>
                <div className={aboutStyles.about__naumen}>
                    <NaumenLogo className={aboutStyles.about__naumenLogo} />
                    <SectionTitle>
                        Naumen — ведущий российский разработчик программных решений для бизнеса и органов власти
                    </SectionTitle>
                    <SectionDescription>
                        С момента своего основания в 2001 году компания NAUMEN приобрела опыт выполнения проектов по
                        разработке и внедрению информационных систем в различных отраслях российской экономики.
                    </SectionDescription>
                    <Button className={aboutStyles.about__naumenButton} isLink href="https://www.naumen.ru/">
                        Посетить сайт
                    </Button>
                </div>
            </Section>
            <Container>
                <FreeDemoForm />
            </Container>
        </Layout>
    );
};

export const pageQuery = graphql`
    query {
        aboutPage: strapiAbout {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            hero {
                buttonText: button_text
                title
                media {
                    youtubeVideoURL
                    content {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
                variant
            }
            team {
                id
                name
                position
                sortOrder
                avatar {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(width: 632, aspectRatio: 1)
                        }
                    }
                }
            }
        }
        articles: allStrapiArticles(filter: {authors: {elemMatch: {id: {ne: 0}}}}) {
            edges {
                node {
                    authors {
                        id
                    }
                }
            }
        }
    }
`;

export default ContactPage;
