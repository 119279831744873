import {useCallback, useContext, useEffect, useState} from "react";
import * as React from "react";
import ArrowWithTail from "../../../assets/svg/arrow-with-tail.svg";
import {SliderApiContext} from "./index";
import * as navigationStyles from "./slider-navigation.module.css";

const SliderNavigation = () => {
    const sliderApi = useContext(SliderApiContext);

    const goToPrevSlide = useCallback(() => {
        if (!sliderApi) {
            return;
        }

        sliderApi.scrollPrev();
    }, [sliderApi]);

    const goToNextSlide = useCallback(() => {
        if (!sliderApi) {
            return;
        }

        sliderApi.scrollNext();
    }, [sliderApi]);

    const [forbiddenScrollDirections, setForbiddenScrollDirections] = useState({prev: true, next: false});

    const updateForbiddenScrollDirections = useCallback(() => {
        if (!sliderApi) {
            return;
        }

        setForbiddenScrollDirections((currentForbiddenScrollDirections) => {
            const {prev: currPrevDirectionState, next: currNextDirectionState} = currentForbiddenScrollDirections;

            const newPrevDirectionState = !sliderApi.canScrollPrev();
            const newNextDirectionState = !sliderApi.canScrollNext();

            if (newPrevDirectionState === currPrevDirectionState && newNextDirectionState === currNextDirectionState) {
                return currentForbiddenScrollDirections;
            }

            return {
                prev: newPrevDirectionState,
                next: newNextDirectionState,
            };
        });
    }, [sliderApi]);

    useEffect(() => {
        if (!sliderApi) {
            return () => {};
        }

        sliderApi.on("init", updateForbiddenScrollDirections);
        sliderApi.on("reInit", updateForbiddenScrollDirections);
        sliderApi.on("select", updateForbiddenScrollDirections);

        return () => {
            sliderApi.off("reInit", updateForbiddenScrollDirections);
            sliderApi.off("reInit", updateForbiddenScrollDirections);
            sliderApi.off("select", updateForbiddenScrollDirections);
        };
    }, [sliderApi, updateForbiddenScrollDirections]);

    return (
        <div className={navigationStyles.sliderNavigation}>
            {(!forbiddenScrollDirections.prev || !forbiddenScrollDirections.next) && (
                <>
                    <button
                        className={navigationStyles.sliderNavigation__button}
                        type="button"
                        onClick={goToPrevSlide}
                        disabled={forbiddenScrollDirections.prev}
                    >
                        <ArrowWithTail />
                    </button>
                    <button
                        className={navigationStyles.sliderNavigation__button}
                        type="button"
                        onClick={goToNextSlide}
                        disabled={forbiddenScrollDirections.next}
                    >
                        <ArrowWithTail />
                    </button>
                </>
            )}
        </div>
    );
};

export default SliderNavigation;
