// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--Dhaa5";
export var hero__title = "hero-module--hero__title--TCzFe";
export var hero__media = "hero-module--hero__media--WwXl7";
export var hero__gradient = "hero-module--hero__gradient--U2AjL";
export var hero__gradient_laptop = "hero-module--hero__gradient_laptop--gaH4h";
export var hero__gradient_tablet = "hero-module--hero__gradient_tablet--7fZ9-";
export var hero__gradient_mobile = "hero-module--hero__gradient_mobile--EJ2-5";
export var hero_textLeft = "hero-module--hero_text-left--neW+y";
export var hero__button = "hero-module--hero__button--XnPMd";
export var hero__gradient_desktop = "hero-module--hero__gradient_desktop--AD6ZC";